module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":20},"url":"https://adminwp.karinagrant.co.uk/graphql","includedRoutes":["/*/*/categories","/*/*/posts","/*/*/information","/*/*/pages","/*/*/media","/*/*/tags","/*/*/taxonomies","/*/*/users","/*/*/menus"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-40,"duration":2000},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 720px)","md":"(max-width: 1024px)","l":"(max-width: 1600px)","xl":"(max-width: 2400px)","portrait":"(orientation: portrait)","navMdL":"(max-width: 810px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KarinaGrant","short_name":"KarinaGrant","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/karinagrant-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c581d31b42d4ff3270a09cb21aae2904"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-53581033-1","GTM-KM43CRR"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity-gotrue/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://karinagrantmarketing.netlify.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.karinagrant.co.uk"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
